/* .navbar-wrapper .main-panel{
	width:100%;
} */

* {
	font-weight: bold !important;
}

.sidebar p, .sidebar i {
	font-weight: 400 !important;
}

button.btn.btn-new-block {
    background-color: #286090 !important;
}

.content-light h5, .content-light h4, .content-light a {
	font-weight: 400 !important;
}

.fz15{
	font-size: 15px;
}

.nav-open .navbar-expand-lg .navbar-collapse {
    position: sticky !important;
}

.nav-open .navbar.navbar-transparent {
    position: sticky !important;
    top: 0 !important;
}

.btn-color {
	background-color: black !important;
}

.sidebar {
	background: transparent;
}

.App {
	text-align: center;
}

td.test {
	width: 600px;

	display: block;
}

td.test1 {
	width: 276px;
	text-align: center;
}

.panel-header {
	height: 70px !important;
	padding: 0 !important;
	background: #001c6b !important;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.navbar-toggler:focus {
	box-shadow: none !important;
}

@media (max-width: 991px) {
	.navbar-expand-lg .navbar-collapse,
	.nav-open .navbar-expand-lg .navbar-collapse {
		height: 58px !important;
	}

	.panel-header {
		height: 58px !important;
	}
	.sidebar .sidebar-wrapper, .off-canvas-sidebar .sidebar-wrapper{
		width: 260px !important;
	}
	.sidebar, .bootstrap-navbar{
		width: 260px !important;
	}
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.active {
	color: red;
}

.dropdown {
	position: relative;
	display: inline-block;
	background: none !important;
}

.dropdown-content {
	display: none;
	position: absolute;
	background-color: #f9f9f9;

	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	padding: 12px 16px;
	z-index: 1;
	right: -11px;
	border-bottom: 1px solid #eee;
}

.dropdown-content p {
	color: #000 !important;
	font-size: 13px !important;
	text-transform: uppercase !important;
}

.dropdown:hover .dropdown-content {
	display: block;
}

.dropdown-content hr {
	color: #f96332;
	height: 0.25px;
	margin: 0px !important;
}

/* #cell-6-undefined div {
  padding-left: 51px !important;
  margin-left: 51px !important;
   overflow: visible !important;
} */

/* #cell-5-undefined div {
  overflow: visible !important;
}
#cell-5-undefined {
  overflow: visible !important;
}

#cell-4-undefined  {
  overflow: visible !important;
}
#cell-6-undefined  {
  overflow: visible !important;
} */


/*vsh*/
.tbl-tradehostory thead tr th {
	color: rgba(0, 0, 0, 0.87);
	font-size: 12px !important;
	font-weight: 500 !important;
}

.tbl-tradehostory tbody tr td {
	font-size: 15px;
	font-weight: bolder;
	color: rgba(0, 0, 0, 0.87);
	/* background-color: #FFFFFF; */
	min-height: 48px;
	/* font-weight: bold; */
}

.trade-space {
	margin: 20px 0;
}

.tradehostory-w {
	width: 0;
}

table.table.tbl-tradehostory tbody {
	border-top: 1px solid #dee2e6 !important;
}

table.table.tbl-tradehostory tfoot {
	border-top: 1px solid #dee2e6 !important;
}

.input-group-append .input-group-text {
	padding: 10px 15px !important;
	border-radius: 0 20px 20px 0 !important;
}

.broker_switch input {
	width: 51px !important;
	height: 31px !important;
}

.r-btn label {
	margin-right: 32px;
	display: inline-flex;
	align-items: center;
}

.r-btn input.demo_status.lictype {
	margin-right: 11px;
}

.btn-new {
	padding: 4px 13px !important;
	margin-right: 10px !important;
}

.d-flex {
	display: flex;
	align-items: baseline;
}

.r-btn {
	margin-top: 17px !important;
	margin-bottom: 17px !important;
}

.btn-new-block {
	padding: 4px 14px !important;
}

a:active {
	/*background-color: #FFFFFF !important;*/
	box-shadow: 0 1px 15px 1px rgb(39 39 39 / 10%) !important;
}

/* client profile */
.profile-user-img {
	margin: 0 auto;
	width: 100px;
	padding: 3px;
	border: 3px solid #d2d6de;
}

.box-body.box-profile {
	background: #fff;
	text-align: center;
}

.profile-username {
	font-size: 21px;
	margin-top: 5px;
}

.content-header {
	position: relative;
	padding: 15px 15px 15px 15px;
}

.content-header>h1 {
	margin-top: 20px;
	font-size: 24px;
}

.content-header>h1>small {
	font-size: 15px;
	display: inline-block;
	padding-left: 4px;
	font-weight: 300;
}

.profile-user-img {
	margin: 17px auto;
	width: 100px;
	padding: 3px;
	border-radius: 50%;
	border: 3px solid #d2d6de;
}

.list-group-item {
	text-align: left;
}

.content-header>h1>small {
	font-size: 14px;
	display: inline-block;
	padding-left: 4px;
	font-weight: 300;
	text-transform: capitalize;
}

.nav-tabs-custom {
	background: #fff;
}

.password {
	padding: 20px;
}

.submit {
	background-color: #3c8dbc;
	/* border-color: #367fa9; */
	color: #fff;
	padding: 10px 40px;
	border: none;
	border-radius: 5px;
	margin: 0px 10px 30px;
}

.Reset {
	background-color: #f4f4f4;
	color: #444;
	border: none;
	padding: 10px 40px;
	border-radius: 5px;
}

.profile_description {
	padding: 10px 20px;
}

.profile_username {
	font-size: 21px;
	margin-top: 5px;
	color: #000;
}

.col-md-3.export-btn button {
	color: #fff;
	background: #f96332;
	border-width: 2px;
	font-weight: 400;
	font-size: 0.8571em;
	line-height: 1.35em;
	border: none;
	margin: 10px 1px;
	border-radius: 0.1875rem;
	padding: 11px 22px;
	cursor: pointer;
	background-color: #f96332;
	color: #FFFFFF;
}

.XlRpR {
	position: relative;
	width: 94% !important;
	display: table;
}


.text-right {
	text-align: right;
}


/* a{
  text-decoration: none;
  color: black;
}

a:visited{
  color: black;
} */

.box::-webkit-scrollbar-track {
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	background-color: #F5F5F5;
	border-radius: 5px
}

.box::-webkit-scrollbar {
	width: 5px;
	background-color: #F5F5F5;
	border-radius: 5px
}

.box::-webkit-scrollbar-thumb {
	background-color: #204166;
	border: 2px solid #204166;
	border-radius: 5px
}


.icons {
	display: inline;
	float: right
}

.notification {
	padding-top: 22px;
	position: relative;
	display: inline-block;
}

.number {
	height: 20px;
	width: 20px;
	background-color: #d63031;
	border-radius: 20px;
	color: white;
	text-align: center;
	position: absolute;
	top: 10px;
	left: 49px;
	padding: 3px;

}

.number span {
	position: absolute;
	top: 0px;
	left: 6px;
	font-size: 14px;
}

.number:empty {
	display: none;
}

.notBtn {
	transition: 0.5s;
	cursor: pointer
}

.notification .fas {
	font-size: 20px;
	padding-bottom: 10px;
	color: white;
	margin-right: 25px;
	margin-left: 35px;
}

.box {
	width: 400px;
	height: 0px;
	border-radius: 10px;
	transition: 0.5s;
	position: absolute;
	overflow-y: scroll;
	padding: 0px;
	left: -300px;
	margin-top: 5px;
	background-color: #F4F4F4;
	-webkit-box-shadow: 0 1px 15px 1px rgb(39 39 39 / 10%);
	-moz-box-shadow: 0 1px 15px 1px rgb(39 39 39 / 10%);
	box-shadow: 0 1px 15px 1px rgb(39 39 39 / 10%);
	cursor: context-menu;
}

.fas:hover {
	color: #d63031;
}

.notBtn:hover>.box {
	height: 60vh
}

ul.txt li {
	padding: 10px;
	border-bottom: 2px solid #f66232;

}

.display {
	position: relative;
}

.cont {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #F4F4F4;
}

.cont:empty {
	display: none;
}

.sec {
	padding: 25px;
	background-color: #fff;
	transition: 0.5s;
}

.profCont {
	padding-left: 15px;
}

.txt {
	vertical-align: top;
	font-size: 16px;
	padding: 5px 20px 0px 20px;
	color: #1c3a5e;
}

.box h5 {
	color: #333;
	text-align: center;
	margin: 10px 0px;
}

.profile.dropdown button {
	background: none !important;
	margin: 0px;
}

.profile.dropdown button:hover {
	background: transparent;
	box-shadow: none !important;
}

.profile.dropdown button:focus {
	background-color: transparent;
	color: #FFFFFF;
	box-shadow: none;
	/* border-color: #fa7a50; */
}

li.user-header img {
	width: 100px;
	text-align: center;
	margin: 0px auto;
}

.profile ul.dropdown-menu {
	width: 205px;
	left: 50px;
}

li.user-footer {
	padding: 0px 10px;
}

li.user-header {
	text-align: center;
}


.filter-text {
	/* border: none !important; */
	padding: 2px 20px;
	border-radius: 5px;
	/* background-color: rgb(248, 212, 212); */
}

.filter-text:focus {
	outline: none !important;
	border-bottom: 1px solid rgb(170, 166, 166) !important;
}


/* .data-table-extensions-filter{
  margin: 24px 13px !important;
} */

/*  Client > Dashboard > Column bold */

.tbl-tradehostory thead tr th {
	margin: 15px 0px !important;
	/* color: #fff !important; */
	font-size: 1em !important;
	font-weight: 700 !important;
}


/*    Header Profile section   */

#dropdownMenu {
	transform: translate3d(-90px, -20px, 0) !important;
}


div.dropdown-menu {
	visibility: hidden !important;
}


::-webkit-scrollbar {
	width: 5px !important;
	border-radius: 15px;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 5px;
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: rgb(238, 93, 93);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgb(252, 3, 3);
}

#dropdownMenuu {
	transform: translate3d(-322px, -29px, 0) !important;
	width: 340px !important;
	height: 400px !important;
	border-radius: 10px !important;
	background-color: #fff;
	overflow-y: scroll !important;
	overflow-x: hidden;
}

.box::-webkit-scrollbar {
	width: 3px;
}

.dropdown-menu:before {
	display: inline-block;
	position: absolute;
	width: 0;
	height: 0;
	vertical-align: middle;
	content: "";
	top: -5px;
	left: 168px !important;
	right: 22px;
	color: #FFFFFF;
	border-bottom: 0.4em solid;
	border-right: 0.4em solid transparent;
	border-left: 0.4em solid transparent;
}

.bedge {
	position: absolute;
	top: 10px;
	left: 39px;
	font-size: 14px;
	background: red;
	font-weight: bold !important;
	width: 22px;
	height: 22px;
	text-align: center;
	outline: none;
	border-radius: 50%;
	border: none;
}

/* .drop-ul{
  width: 330px !important;
  height: 500px !important;
  overflow-y: scroll !important;
  overflow-x: auto !important;
} */

.bsgpsK div:first-child {
	overflow: visible !important;
}

.offcanvas-start {
	width: 300px !important;
}

.sidebar,
.bootstrap-navbar {
	position: fixed;
	display: block;
	top: 0;
	height: 100vh;
	width: 260px;
	/* right: auto; */
	left: 0;
	z-index: 1032;
	visibility: visible;
	overflow-y: visible;
	padding: 0;
	transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
	/* transform: translate3d(0px, 0, 0) !important; */
}


/* Header trash Hover */

.hover:hover {
	color: red;
	transition: all .3s linear;
	cursor: pointer;
}

.edit_hover:hover {
	color: rgb(59, 182, 59);
	transition: all .3s linear;
	cursor: pointer;

}

.edit_hover {
	color: #111;
}

.backHover:hover {
	color: rgb(247, 0, 0);
	transition: all .3s linear;
	cursor: pointer;
}

.addhover:hover {
	color: rgb(49, 9, 9);
	transition: all .3s linear;
	cursor: pointer;
}

.addhover {
	color: rgb(49, 9, 9);
	transition: all .3s linear;
	cursor: pointer;
}

.h {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: (-50%, 50%);
	transform: translate(-50%, -50%);

}


/* .rotate-center:hover{
  color: rgb(170, 135, 157);
  transition: all .3s linear;
  cursor: pointer;

} */
.rotate-center {
	-webkit-animation: rotate-center 0.6s ease-in-out both;
	animation: rotate-center 0.6s ease-in-out both;
}

@-webkit-keyframes rotate-center {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}

	50% {
		-webkit-transform: rotate(90deg);
		transform: rotate(90deg);
	}

	100% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}

@keyframes rotate-center {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}

	50% {
		-webkit-transform: rotate(180deg);
		transform: rotate(180deg);
	}

	100% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
}


.hoverRefresh:hover {
	/* transform: rotate(360); */
	color: rgb(170, 135, 157);
	transition: all .3s linear;
	cursor: pointer;
}

/*======================
	404 page
=======================*/


.page_404 {
	padding: 40px 0;
	background: #fff;
	font-family: 'Arvo', serif;
}

.page_404 img {
	width: 100%;
}

.four_zero_four_bg {

	background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
	height: 400px;
	background-position: center;
	width: 1254px;
	margin-top: 70px;

}

.contant_box_404 {
	margin-top: 10px !important;
}


.four_zero_four_bg h1 {
	font-size: 80px;
}

.four_zero_four_bg h3 {
	font-size: 80px;
}

.link_404 {
	color: #fff !important;
	padding: 10px 20px;
	background: #39ac31;
	margin: 20px 0;
	display: inline-block;
}

.contant_box_404 {
	margin-top: -50px;
}

.tablecolor {}

thead.tablecolor tr th {
	font-size: 20px !important;
}

.tableheight {
/*	height: 60vh !important;*/
	overflow-y: scroll !important;
}

/* Translator Css --------------------------------------- */

/* .goog-te-gadget {
	color: transparent !important;
}

.goog-logo-link {
	display: none !important;
}

.goog-te-banner-frame.skiptranslate {
	display: none !important;
}

body {
	top: 0px !important;
} */

/* ----------------------- Responsive Design  */

@media all and (max-width: 425px) {

	#totalLicence {
		text-align: center;
		flex-direction: column;
	}


}

/* @media all and (min-width: 768px) and (max-width: 1024px) { }
 
@media all and (min-width: 480px) and (max-width: 768px) { }
 
@media all and (max-width: 480px) { } */

i.now-ui-icons.ui-1_bell-53.fs-5.mt-1,
i.now-ui-icons.users_single-02.mt-1.fs-5,
div#navigation i {
	color: #fff !important;
	font-style: normal;
}

button.btn.btn-color.btn-block,
.blob-small {
	background: #286090 !important;
}

html.nav-open .content {
    margin-top: -45px !important;
}

.card .card-header .card-title:first-child {
	margin-top: 30px;
	font-size: 30px !important;
	font-weight: 700;
}

.sidebar {
	background: #222d32 !important;

}

.sidebar a {
	color: #b8c7ce !important;
}

.sidebar .nav li>a,
.off-canvas-sidebar .nav li>a {
	margin: 0px 0px 0 !important;
	border-radius: 0px !important;

}

.sidebar .nav li.active>a,
.off-canvas-sidebar .nav li.active>a,
.sidebar .nav li:hover>a,
.off-canvas-sidebar .nav li:hover>a {
	background-color: #1a2226 !important;
	color: #fff !important;
	box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
}

.sidebar .nav p,
.sidebar .nav i {
	color: #fff !important;
}

input.form-control,
select.order_type.form-control,
select.product_type.form-control,
select.form-control {
	background: #fff;
	background-color: #fff;
	border-radius: 0;
	height: 40px;
}

.form-switch .form-check-input {
	width: 4.5em !important;
	height: 2.5em !important;

}

body thead.tablecolor tr th {
	font-size: 16px !important;
}

.jCLYZH,
.jxflYm,
.kgEPYv,
.eSTlnH,
.gZWIKX,
.kxbzgr,
.bnAwAJ,
.hXcIDD,
.fVrZFd,
.hITlxo,
.sc-hKMtZM,
.sc-jqUVSM {
	background-color: transparent !important;
	color: #333 !important;

}

.rdt_TableCol_Sortable {
	font-size: 20px;
	text-transform: capitalize !important;
	color: #333;
}

/*.bMgaAx div {
	font-size: 15px;
	font-weight: 600;
}*/

b.form-control.input-lg.input-white.shadow-5 {
	border-radius: 0;
	background: #fff;
	height: 40px;
}

textarea#message {
	background: #fff;
}

.data-table-extensions-filter {
	background: #fff;
}

p.h5.text-center {
	text-align: left !important;
	font-size: 30px;
	margin-top: 14px;
	margin-bottom: 0px;
}

select.form-control,
select.order_type.form-control,
select.product_type.form-control {
	/* width: 110px !important; */
	color: #000000;
	border-radius: 0;
	background-color: white;
	background-image: linear-gradient(45deg, transparent 50%, #000 50%), linear-gradient(135deg, #000 50%, transparent 50%);
	background-repeat: no-repeat;
	background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
	background-size: 5px 5px, 5px 5px, 1px 1.5em;
}

.login-page{
	
}

.btn,
.navbar .navbar-nav>a.btn {
	color: #FFFFFF;
	background: #3c8dbc !important;
}

.font_mag {
	font-size: 18px !important;
	margin-top: 16px !important;
}

.card.card-chart.hover-card.bg-green {
	background: #00a65a !important;
}

.card.card-chart.hover-card.bg-red {
	background: #dd4b39 !important;
}

.card.card-chart.hover-card .stats {
	background-color: rgba(0, 0, 0, 0.1) !important;
}

.card.card-chart {
	margin: 10px 0px;
	height: 140px !important;
	background: #337ab7 !important;
}

.stats {
	background-color: #2e6ea4 !important;
	position: unset !important;
	/* bottom: 0px !important; */
	padding: 6px;
}

.card .card-footer {
	padding: 0;
}

.card-chart .card-header .card-category {
	color: #fff !important;
}

i.now-ui-icons.arrows-1_share-66 {
	color: #fff !important;
}

.hover-card .card-header .card-title:last-child {
	color: #fff;
}

.fFpvxo:not(:last-of-type),
div#row-10 {
	background: transparent;
}

.bg_image {
	background-image: url(http://trade.startalgo.com//uploads/startalgo_logo3.png);
}

.tbl-tradehostory {
	overflow: auto;
	padding-bottom: 10px;

	/* background-image: url(http://trade.startalgo.com//uploads/startalgo_logo3.png); */
	background-repeat: no-repeat;
	background-position: top center;
	background-size: contain;
	background-color: #fff;
}

.font-large {
	font-size: 20px;
}

.card .card-body {
	padding: 15px 15px 10px 15px !important;
	margin-top: 10px;
}

input#formBasicEmail,
input#formBasicPassword {
	height: auto;
}

.bg-light.w-100.rounded-3.p-3.d-flex.flex-column {
	margin-top: 90px !important;
}

/* .main {
  margin: 0 auto;
  display: block;
  height: 100%;
  margin-top: 60px;
}
.mainInner{
  display: table;
  height: 100%;
  width: 100%;
  text-align: center;
}
.mainInner div{
  display:table-cell;
  vertical-align: middle;
  font-size: 3em;
  font-weight: bold;
  letter-spacing: 1.25px;
}
#sidebarMenu {
  height: 100%;
  position: fixed;
  left: 0;
  width: 250px;
  margin-top: 60px;
  transform: translateX(0px);
  transition: transform 250ms ease-in-out;
  background: linear-gradient(180deg, #FC466B 0%, #3F5EFB 100%);
}
.sidebarMenuInner{
  margin:0;
  padding:0;
  border-top: 1px solid rgba(255, 255, 255, 0.10);
}
.sidebarMenuInner li{
  list-style: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}
.sidebarMenuInner li span{
  display: block;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.50);
}
.sidebarMenuInner li a{
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}
input[type="checkbox"]:checked ~ #sidebarMenu {

 transform: translateX(-250px);
}

input[type=checkbox] {
  transition: all 0.3s;
  box-sizing: border-box;
  display: none;
}
.sidebarIconToggle {
  transition: all 0.3s;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  height: 100%;
  width: 100%;
  top: 22px;
  left: 15px;
  height: 22px;
  width: 22px;
}
.spinner {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #fff;
}
.horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}
.diagonal.part-1 {
  position: relative;
  transition: all 0.3s;
  box-sizing: border-box;
  float: left;
}
.diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}
input[type=checkbox]:checked .main-panel {
  width: 100%;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 0;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(135deg);
  margin-top: 8px;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(-135deg);
  margin-top: -9px;
} */
.card .form-group label {
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 5px;
	color: #000000;
}

.table>thead>tr>th {

	font-weight: 600 !important;

}

h6,
.h6 {
	font-size: 20px !important;
	font-weight: 600 !important;

}

.data-table-extensions>.data-table-extensions-filter {
	border: 1px solid #8080805c !important;
}

body .sidebar .nav li>a,
.off-canvas-sidebar .nav li>a {
	margin: 0px !important;
	font-size: 14px !important;
	text-transform: capitalize !important;
}

button.btn.px-3.rounded-3.btn-color {
	border-radius: 3px !important;
}

button.navbar-toggler {
	background: transparent !important;
}

.col-md-2 .form-check label.form-check-label {
	font-size: 0.8571em;
	margin-bottom: 5px;
	color: #333;
	padding-left: 6px !important;
	font-weight: 700;
}

.nav-toggle-api {
	display: flex;
	flex-direction: row-reverse;
}

.broker_switch.mb-2.form-switch {
	margin-right: 10px;
}

h6,
.h6 {
	font-size: 1em;
	font-weight: 700;
	text-transform: capitalize !important;
}

@media screen and (max-width: 991px) {
	ul.navbar-nav.button-nav {
		width: auto !important;
		display: flex !important;
		flex-direction: row !important;
	}

	.navbar .navbar-nav .nav-item:not(:last-child) {
		/* margin-bottom: 10px; */
		margin-top: -10px !important;
	}

}

body .navbar.navbar-transparent {
	background-color: #001c6b !important;
	position: fixed;
	height: 68px;
}

.navbar-expand-lg .navbar-collapse,
.nav-open .navbar-expand-lg .navbar-collapse {
	display: flex !important;
	flex-basis: auto;
	position: fixed;
	width: 100%;
	right: 0;
	height: 68px;
	background: #001c6b !important;
}

.navbar .navbar-wrapper {
	display: inline-flex;
	align-items: center;
	position: relative;
	z-index: 99999;
}

.panel-header,
.nav-open .panel-header {
	height: 240px;
	padding-top: 80px;
	padding-bottom: 50px;
	/* background: #141E30; */
	background-color: #000;
	position: fixed !important;
	overflow: hidden;
	width: 100%;
	display: none;
}

.main-panel>.content {
	margin-top: 0px !important;
	padding: 0 10px 30px !important;
}

.main-panel>.mt20 {
    margin-top: 20px !important;
}

.sidebar .sidebar-wrapper::-webkit-scrollbar {
    display: none;
}

body .nav-open .navbar.navbar-transparent {
	background-color: #001c6b !important;
	position: fixed;
}

/* client css */

#navigation h6 {
	font-size: 17px !important;
	margin-bottom: 0px;
}

@media (min-width: 320px) and (max-width: 375px) {
	#navigation{
		top: 30px;
	}
	#navigation i{
		font-size: 14px !important;
	}
	.navbar .navbar-brand{
		font-size: 10px !important;
	}
}

@media (max-width: 420px) {
	/*.nav-toggle-api{
		display: none !important;
	}*/
	#navigation i{
		font-size: 10px;
	}
	.bedge{
		left: 17px;
		font-size: 10px;
		width: 16px;
		height: 16px;
	}
	.form-switch .form-check-input {
		width: 2.5em !important;
		height: 1.5em !important;
	}
	.navbar .navbar-brand{
		font-size: 6px !important;
		padding-top: 0px !important;
		padding-bottom: 0px !important;
	}
}

@media (min-width: 420px) and (max-width: 575px) {
	.navbar .navbar-brand{
		font-size: 10px !important;
	}
}

.broker-response .accordion-collapse.collapse.show {
    width: 66% !important;
}

.broker-response .accordion-collapse.collapse .accordion-body {
    display: none;
}

.broker-response .accordion-collapse.collapse.show  .accordion-body{
display: block;
}

