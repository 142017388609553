/* .btn-color{
    /* background: "black" !important; 
    color: wheat;
} */
.fix-image{
    width: 100%;
    /* height: 200px; */
    object-fit: contain;
}

.justify-content-center.flex-column.d-flex.mx-auto.rounded-3 {
    background: #fff;
}
.card .card-body {
    padding: 15px 15px 10px 15px!important;
}
.maunt{
	float: right;
}
.XlRpR {
    width: 100%!important;
}

.password {
     padding: 0px!important;
}
.password label{
	margin-bottom: 6px;
}
.card-title {
    font-size: 18px!important;
}
.export-btn {
  text-align: right;
}
.visiablity{
	margin-left: 20px!important;
}
.export-btn button{
	background-color: #f96332!important;
}
.card-body.top_spacing {
    margin-top: 30px;
}
.table>thead>tr>th{
	font-size: 1em !important;
}
.ReactTags__selected span.ReactTags__tag{
	margin: 0 5px 10px!important;
}
.row.trade-space {
    margin-top: 30px;
}




@media (max-width: 992px) and (min-width: 768px) {
	.navbar-expand-lg .navbar-collapse {
    display: flex!important;
	}
	.navbar-nav {
    display: flex;
    flex-direction: row!important;
    list-style: none;
	}
	.profile.dropdown {
    	margin-top: -16px!important;
	}
    .container-fluid{
        height:auto!important;
    }
    
}


@media (max-width: 767px) and (min-width: 425px) {
    .card label {
        margin-top:16px!important;
    }
	.profile.dropdown {
    	margin-top: -16px!important;
	}
	.collapse:not(.show) {
     display: block!important; 
	}
	.navbar-nav {
    display: block!important;
    flex-direction: row;
    position: absolute;
    right: -4px;
    right: 0;
    top: 20px;
	}
    .bg-light.p-3.rounded-3 {
        margin-top: 140px!important;
    }
    #dropdownMenuu {
    width: 340px !important;
    height: 400px !important; 
    background-color: #fff;
    overflow-y: scroll !important;
    margin: 0px;
    float: right!important;
    position: relative;
    left: 70px!important;
    top: 20px!important;
    }
 }

 @media (max-width: 424px) and (min-width: 375px) { 
 	.profile.dropdown {
    	margin-top: 0px !important;
	}
	.collapse:not(.show) {
     display: block!important; 
	}
	.navbar-nav {
        display: block!important;
        flex-direction: row;
        position: relative;
        right: 0;
        top: 0px;
	}
    .bg-light.p-3.rounded-3 {
        margin-top: 80px!important;
    }
    #dropdownMenuu {
    transform: translate3d(-260px, -29px, 0) !important;
    width: 340px !important;
    height: 400px !important;
    background-color: #fff;
    overflow-y: scroll !important;
    margin: 0px;
    float: right!important;
    position: relative;
    left: 50px!important;
    top: 20px!important;
    }

 }

.w-15{
    width: 15% !important;
}
.form-check input[type="checkbox"], .radio input[type="radio"] {
    position: inherit !important;
}
.fix-height{
    height: 328px;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* .hover-card :hover{
    background-color: pink;
    transition: .3s all linear; 
} */